import React from 'react';
import Helmet from 'react-helmet';

import { Intro } from 'components/intro/Intro';
import { Segment } from 'components/segment/Segment';

// tslint:disable no-default-export
export default () => (
  <>
    <Helmet title="About" />

    <Intro>About me</Intro>
    <Segment>
      Hi! I'm a software engineer specializing in front end JavaScript, especially React and Vue. Problem solving led me to discover programming -- to automate repetitive Excel work I learned Visual Basic -- and I'm
      always learning.
      <br></br>
      <br></br>
      I'm inspired by the endless possibilities to carve structure out of chaos and believe strongly
      in using tech to promote sustainability and equitability. I'm happiest when exploring and most
      fulfilled when solving big problems in order to foster the common good.
      <br></br>
      <br></br>
      Even before I began programming, I’ve been involved with technology as a research engineer,
      writer, and teacher. Looking for an opportunity to be pushed to new limits with a team that
      values growth, thoughtfulness, and collaboration. thirst for challenging problems
    </Segment>
  </>
);
